import { addBreadcrumb, breadcrumbsIntegration, captureException, init } from '@sentry/solidstart';
import { getAccessibleName } from 'accname';

export function initSentry({ dsn, environment, release }: { dsn?: string; environment: string; release: string }) {
	if (!dsn) {
		return;
	}

	const integrations: Parameters<typeof init>[0]['integrations'] = [
		breadcrumbsIntegration({
			history: true,
			sentry: true,
		}),
	];

	init({
		dsn,
		environment,
		integrations,
		release,
		ignoreErrors,
		beforeBreadcrumb(breadcrumb, hint) {
			if (hint?.event.currentTarget) {
				const name = getAccessibleName(hint.event.target);
				if (name) {
					breadcrumb.message = `"${name}" (${breadcrumb.message})`;
				}
			}
			return breadcrumb;
		},
	});

	window.addEventListener('vite:preloadError', (e) => {
		addBreadcrumb({ message: 'Caught dynamic import preload error, will reload window…' });
		captureException(e.payload);
		window.location.reload();
	});
}

export const ignoreErrors = [
	/resizeobserver loop completed/i,
	/transition was (aborted|skipped)/i,
	/view transition/,
	'Attempted to assign to readonly property',
	/Invalid `prisma\./i,
	/fetch is aborted/i,
	/failed to fetch/i,
	/(load|fetch) failed/i,
];
