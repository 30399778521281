import { MetaProvider, Title } from '@solidjs/meta';
import './app.css';
import { Router } from '@solidjs/router';
import { FileRoutes } from '@solidjs/start/router';
import { UIProvider } from '@troon/ui';
import { withSentryRouterRouting } from '@sentry/solidstart/solidrouter';
import { RootContext } from './providers/root';
import { ErrorBoundary } from './components/error-boundary';

const SentryRouter = withSentryRouterRouting(Router);

export default function App() {
	return (
		<MetaProvider>
			<Title>Troon Operator</Title>
			<SentryRouter
				root={(props) => (
					<ErrorBoundary>
						<UIProvider baseUrl={`https://${window.location.host}`}>
							<RootContext>{props.children}</RootContext>
						</UIProvider>
					</ErrorBoundary>
				)}
			>
				<FileRoutes />
			</SentryRouter>
		</MetaProvider>
	);
}
