import { mount, StartClient } from '@solidjs/start/client';
import { createApiClient } from '@troon/api-client';
import { ErrorBoundary } from 'solid-js';
import { initSentry } from './modules/sentry';

createApiClient({
	baseUrl: window.__CONFIG__.apiBaseUrl,
});

if (import.meta.env.TROON_SENTRY_ENABLED) {
	initSentry({
		dsn: window.__CONFIG__.sentryDsn,
		environment: window.__CONFIG__.env,
		release: window.__CONFIG__.sha,
	});
}

mount(() => {
	return (
		<ErrorBoundary fallback={(error) => <div>{error}</div>}>
			<StartClient />
		</ErrorBoundary>
	);
}, document.getElementById('app')!);
